import React, {forwardRef, useEffect, useRef, useState} from 'react'
import {Dropdown, Input, InputGroup, InputGroupText, Label} from 'reactstrap'
import Api, { formatDate } from '../Shared'
import { TiDocumentAdd, TiRefreshOutline } from "react-icons/ti";
import { BiSearchAlt} from "react-icons/bi";
import { IoMdRefresh } from "react-icons/io";
import { HiOutlineTrash, HiRewind } from "react-icons/hi";
import { RiArrowUpDownFill } from 'react-icons/ri'
import { FaSkullCrossbones } from 'react-icons/fa'
import { FiChevronsDown, FiChevronsUp, FiEdit } from "react-icons/fi";
import { FaEraser } from "react-icons/fa";
import NewActivationCode from "./Modals/NewActivationCode"
import Table from './Table'
import {Styles} from './Styles'
import DatePicker from 'react-datepicker';
import EditActivationCode from './Modals/EditActivationCode';

export default function ActivationCodes(){

  // custom page variables
  let [loading,setLoading]=useState(true)
  let [activationCodesData,setActivationCodesData]=useState([])
  let [newModalOpen,setNewModalOpen]=useState(false)
  let [rowsPerPage,setRowsPerPage]=useState(10)
  let [currentPage,setCurrentPage]=useState(1)
  let [totalPages,setTotalPages]=useState(0)
  let [asc,setAsc]=useState(0)
  let [desc,setDesc]=useState(0)
  let [showDeleted,setShowDeleted]=useState(false)
  let [editRow,setEditRow]=useState(null)
  let [userRole,setUserRole]=useState()

  // search variables
  let [registerSearch,setRegisterSearch]=useState('')
  let [activationSearch,setActivationSearch]=useState('')
  let [dbcSearch,setDBCSearch]=useState('')
  let [customerSearch,setCustomerSearch]=useState('')
  let [timeStampSearch,setTimeStampSearch]=useState(null)
  let [isDeletedSearch,setIsDeletedSearch]=useState(false)

  // triggers when all the values are set in clearSearch function
  let [triggerClear,setTriggerClear]=useState(false)

  async function getUserRole(){
    const res = await Api({method:'get_user_info'})
    if(res&&res.userRole){
      setUserRole(res.userRole)
      res.rowsPerPage&&setRowsPerPage(res.rowsPerPage)
    }
    return
  }

  // send db about users rows column
  async function setRows(rows){
    let res=await Api({method:'set_rows_per_page',rows})
    if(res.success)
      setRowsPerPage(rows)
    else
      alert('Couldn\' t change rows per page. Error:\n'+res.error)
  }

  // custom columns of this page
  const columns=React.useMemo(
    ()=>[{
      Header:({column:{id}})=><div onClick={()=>{
        setAscDesc(id)
      }}>Register {getIconAscDesc(id)}</div>,
      accessor:'registerSN'
    },{
      Header:({column:{id}})=><div onClick={()=>{
        setAscDesc(id)
      }}>Activation Code {getIconAscDesc(id)}</div>,
      accessor:'activationCode',
    },{
      Header:({column:{id}})=><div onClick={()=>{
        setAscDesc(id)
      }}>DBC {getIconAscDesc(id)}</div>,
      accessor:'dbc'
    },{
      Header:({column:{id}})=><div onClick={()=>{
        setAscDesc(id)
      }}>Time Stamp {getIconAscDesc(id)}</div>,
      accessor:'usedTimeStamp'
    },{
      Header:({column:{id}})=><div onClick={()=>{
        setAscDesc(id)
      }}>Customer {getIconAscDesc(id)}</div>,
      accessor:'customer'
    },{
      show:["1","2"].includes(userRole),
      className:'options',
      Header:'Options',
      Cell:({row:{original}})=>{
        return showDeleted ? 
        <div className='customButtonGroup'>
            {(["1"].includes(userRole))&&
              <div
                onClick={()=>{hardDeleteActivationCode(original.registerSN)}}
                style={{backgroundColor:'#084298',width:'30px',height:'30px',color:'white'}}
                title='Hard Delete'
                className='customButton'>
                  <FaSkullCrossbones size={15}/>
              </div>}
              <div
                onClick={()=>{recoverActivationCode(original.registerSN)}}
                style={{backgroundColor:'#0d6efd',width:'30px',height:'30px',color:'white'}}
                className='customButton'><TiRefreshOutline size={15}/></div>
        
          </div>
        : (
        
        <div className='customButtonGroup'>
          {(["1"].includes(userRole))&&<div onClick={()=>{hardDeleteActivationCode(original.registerSN)}} style={{backgroundColor:'#084298',width:'30px',height:'30px',color:'white'}} title='Hard Delete' className='customButton'><FaSkullCrossbones size={15}/></div>}
          <div onClick={()=>{deleteActivationCode(original.registerSN)}} style={{backgroundColor:'#d3002a',width:'30px',height:'30px',color:'white'}} title='Soft Delete' className='customButton'><HiOutlineTrash size={15}/></div>
          <div onClick={()=>{setEditRow(original)}} style={{backgroundColor:'#f19241',width:'30px',height:'30px',color:'white'}} className='customButton'><FiEdit size={15}/></div>
          <div onClick={()=>{eraseTimeStamp(original.registerSN)}} style={{backgroundColor:'#0d6efd',width:'30px',height:'30px',color:'white'}} className='customButton'><FaEraser size={15}/></div>
        </div>
        )
      },
      width:'10%'
    }
    ]
  )

  // triggers when refresh button gets clicked
  function clearSearch(callback){
    setRegisterSearch('')
    setActivationSearch('')
    setDBCSearch('')
    setCustomerSearch('')
    setTimeStampSearch(null)
    setAsc('')
    setDesc('')
    setCurrentPage(1)
    setTriggerClear(!triggerClear)
  }

  useEffect(()=>{
    getActivationCodes()
    getUserRole()
  },[triggerClear])

  // called when the page is called or one of get data buttons are clicked
  async function getActivationCodes(){
    setLoading(true)

    let timeStampSearchInstance=null
    if(timeStampSearch)
      timeStampSearchInstance=formatDate(new Date(timeStampSearch))

    let response=await Api({method:'get_activation_codes',
      registerSearch,
      activationSearch,
      dbcSearch,
      timeStampSearch:timeStampSearchInstance,
      customerSearch,
      isDeletedSearch,
      currentPage,
      rowsPerPage,
      asc,
      desc
    })
    if(response.success&&response.success===true){
      setActivationCodesData(response.activation_codes)
      setTotalPages(Math.ceil(response.row_count/rowsPerPage))
      setShowDeleted(response.show_deleted)
    }
    else{
      alert('Activation codes couldn\' t loaded.\nError:\n'+(response.error?response.error:'Coudln\' t get any response from server.'))
      setActivationCodesData([])
    }
  }

  useEffect(()=>{
    if(activationCodesData!=[])
      setLoading(false)
  },[activationCodesData])

  useEffect(()=>{
    if(asc)
      getActivationCodes()
  },[asc])

  useEffect(()=>{
    if(desc)
      getActivationCodes()
  },[desc])

  // is called when page is loaded
  useEffect(()=>{
    getActivationCodes()
  },[])

  const isDeletedSearchMount=useRef(false)
  useEffect(()=>{

    // checks if request is from first render
    if(!isDeletedSearchMount.current){
      isDeletedSearchMount.current=true
      return
    }

    getActivationCodes()
  },[isDeletedSearch])

  const isRowsPerPageMount=useRef(false)
  useEffect(()=>{

    // checks if request is from first render
    if(!isRowsPerPageMount.current){
      isRowsPerPageMount.current=true
      return
    }

    getActivationCodes()
  },[rowsPerPage])

  useEffect(()=>{
    if(totalPages!=0 && currentPage>totalPages)
      setCurrentPage(totalPages)
    else
      getActivationCodes()
  },[currentPage])

  async function deleteActivationCode(register){
    let res=await Api({method:'delete_activation_code',register})
    if(res.success)
      getActivationCodes()
    else
      alert('Selected row couldn\' t deleted. Error Message:\n'+res.error)
  }

  async function hardDeleteActivationCode(register){

    if(!window.confirm('If you delete this serial number, all the licenses attached to this serial number will be deleted too. Do you really delete this row from database?'))
      return

    let res=await Api({method:'hard_delete_activation_code',register})
    if(res.success)
      getActivationCodes()
    else
      alert('Selected row couldn\' t deleted. Error Message:\n'+res.error)
  }

  async function eraseTimeStamp(register){
    if(!window.confirm('Do you want to delete selected row\' s time stamp?'))
      return

    let res=await Api({method:'erase_time_stamp_activation_code',register})
    if(res.success)
      getActivationCodes()
    else
      alert('Selected row couldn\' t deleted. Error Message:\n'+res.error)
  }

  async function recoverActivationCode(register){
    let res=await Api({method:'recover_activation_code',register})
    if(res.success)
      getActivationCodes()
    else
      alert('Selected row couldn\' t deleted. Error Message:\n'+res.error)
  }
  
  function setAscDesc(id){
    setCurrentPage(1)
    if(asc==id){
      setDesc(id)
      setAsc('')
    }
    else if (desc==id){
      setAsc(id)
      setDesc('')
    }
    else{
      setAsc(id)
      setDesc('')
    }
    }

    function getIconAscDesc(id){
      if(asc==id)
        return <FiChevronsUp size='18' color='#42ba96' style={{float:'right'}}/>
      else if (desc==id)
        return <FiChevronsDown size='18' color='#d3002a' style={{float:'right'}}/>

      return <RiArrowUpDownFill size='18' color='#ffffff' style={{float:'right'}}/>
    }

  const TimeStampSearch = forwardRef(({ value, onClick,onChange }, ref) => (
    <InputGroup>
      <InputGroupText>Time Stamp</InputGroupText>
      <Input ref={ref} value={value} onChange={onChange} onClick={onClick} placeholder='...'/>
    </InputGroup>
  ));

  return <><Styles>
          <div className='dataHeader'>
            <div className='searchKit'>
              <div className='column'>
                <InputGroup>
                  <InputGroupText>Register</InputGroupText>
                  <Input value={registerSearch} onChange={e=>setRegisterSearch(e.target.value)} placeholder='...'/>
                </InputGroup>
                <InputGroup>
                  <InputGroupText>Activation</InputGroupText>
                  <Input value={activationSearch} onChange={e=>setActivationSearch(e.target.value)} placeholder='...'/>
                </InputGroup>
                <InputGroup>
                  <InputGroupText>DBC</InputGroupText>
                  <Input value={dbcSearch} onChange={e=>setDBCSearch(e.target.value)} placeholder='...'/>
                </InputGroup>
              </div>
              <div className='column'>
                <InputGroup>
                  <InputGroupText>Customer</InputGroupText>
                  <Input value={customerSearch} onChange={e=>setCustomerSearch(e.target.value)} placeholder='...'/>
                </InputGroup>
                  <DatePicker customInput={<TimeStampSearch />} isClearable selected={timeStampSearch} dateFormat="yyyy-MM-dd" onChange={e=> {setTimeStampSearch(e)}}/>
                  <div className='checkBoxContainer'>
                    <div className={'checkBoxCircle '+ (isDeletedSearch?'checkBoxCircleOpen':'checkBoxCircleClose')} onClick={()=>setIsDeletedSearch(()=>{
                      return !isDeletedSearch
                    })}>
                      <span className={'checkBoxText '+ (isDeletedSearch?'checkBoxTextOpen':'checkBoxTextClose')}>{ isDeletedSearch?'Deleted':'Available' }</span>
                    </div>
                  </div>
              </div>
            </div>
            <div className='buttonContainer'>
              <div className='pageBox'>
                <select className='pageSelect' type='number' value={rowsPerPage} onChange={e=>{
                  let {value}=e.target
                  if(value>0){
                    setRows(value)
                  }
                }}>
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                </select>
                <div className='pageText'>Rows Per Page</div>
              </div>
              {["1","2"].includes(userRole)&&<div style={{backgroundColor:'#d3002a'}} className='customButton' onClick={()=>{ setNewModalOpen(true) }}><TiDocumentAdd size={50}/></div>}
              <div style={{backgroundColor:'#42ba96'}} className='customButton' onClick={async ()=>{
                let el=document.getElementById('scroll')
                el.scrollTo(0,0)
                clearSearch(()=>getActivationCodes())
              }}><IoMdRefresh size={50}/></div>
              <div style={{backgroundColor:'#f19241'}} className='customButton' onClick={()=>{
                let el=document.getElementById('scroll')
                el.scrollTo(0,0)
                currentPage==1?getActivationCodes():setCurrentPage(1)
              }}><BiSearchAlt size={50}/></div>
            </div>
          </div>
            <hr/>
          <div className='contentContainer'>
            {loading&&<div className='loading'>Loading...</div>}
            <div className='dataContainer' id='scroll'>
              <Table columns={columns} data={activationCodesData} hiddenColumns={["1","2"].includes(userRole)}/>
            </div>
          </div>
          <div className='dataFooter'>
              <div className='buttonSetLeft'>
                <button disabled={!(currentPage>1)} onClick={()=>{
                  if(currentPage>1)
                    setCurrentPage(1)
                  }}>{'|<'}</button>
                <button disabled={!(currentPage>1)} onClick={()=>{
                  if(currentPage>1)
                    setCurrentPage(currentPage-1)
                  }}>{'<'}</button>
              </div>
                <div className='currentPage'><input style={{ width:(20+currentPage.toString().length*10)+'px' }} type='number'
                  size={2}
                  value={currentPage}
                  onChange={e=>{
                    let {value}=e.target

                    if(value>0 && value<999){
                      setCurrentPage(value)
                  }
                }}/>/ {totalPages}</div>
              <div className='buttonSetRight'>
                <button disabled={!(currentPage<totalPages)} onClick={()=>{
                    if(currentPage<totalPages)
                      setCurrentPage(currentPage+1)
                }}>{'>'}</button>
                <button disabled={!(currentPage<totalPages)} onClick={()=>{
                    if(currentPage<totalPages)
                      setCurrentPage(totalPages)
                }}>{'>|'}</button>
              </div>
          </div>
      </Styles>

      <NewActivationCode refresh={()=>getActivationCodes()} isOpen={newModalOpen} toggle={()=>setNewModalOpen(!newModalOpen)}/>
      <EditActivationCode refresh={()=>getActivationCodes()} isOpen={editRow!=null} selectedRow={editRow} toggle={()=>setEditRow(null)}/>

    </>
}