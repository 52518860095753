import {React, useEffect, useState} from "react"
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap"
import Api, { formatDate } from "../../Shared"
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

export default function NewLicense ({refresh,isOpen,toggle}){

    const [register,setRegister]=useState(null)
    const [module,setModule]=useState(null)
    const [moduleSN,setModuleSN]=useState('')
    const [startDate,setStartDate]=useState(new Date())
    const [endDate,setEndDate]=useState()
    const [registerOptions,setRegisterOptions]=useState([])
    const [moduleOptions,setModuleOptions]=useState([])
    const [isActive,setIsActive]=useState(true)


    useEffect(()=>{
        if(isOpen==true){
            getRegisterOptions()
            getModuleOptions()
        }
    },[isOpen])

    async function getRegisterOptions(){
        let res=await Api({method:'get_register_options'})

        if(res&&res.success)
            setRegisterOptions(res.register_options.map(ro=>({value:ro.registerSN,label:ro.registerSN})))

    }

    async function getModuleOptions(){
        let res=await Api({method:'get_module_options'})

        if(res&&res.success)
            setModuleOptions(res.module_options.map(ro=>({value:ro.id,label:ro.name,moduleCount:ro.moduleCount})))
    }


    async function addLicense(e){
        e.preventDefault()

        if(!(register&&module&&startDate&&endDate&&moduleSN)){
            alert('All the fields must be filled')
            return
        }

        let res=await Api({method:'add_license',
            moduleSN,
            registerSN:register.label,
            moduleID:module.value,
            startDate:formatDate(startDate),
            endDate:formatDate(endDate),
            isActive:isActive?'X':'N'}
        )

        if(res&&res.success){
            toggle()
            refresh()
            clearData()
        }
        else
            alert('License couldn\'t added. Error: \n'+res.error)
    }

    function clearData(){
        setRegister(null)
        setModule(null)
        setModuleSN('')
        setStartDate(new Date())
        setEndDate()
        setIsActive(true)
    }

    useEffect(()=>{
        if(isOpen)
            clearData()
    },[isOpen])
    return <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>
            Add New License
        </ModalHeader>
        <ModalBody>

            <Form>
                <FormGroup>
                    <Label>Register SN <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <Select options={registerOptions} onChange={item=>{
                        if(module&&module.value=='QMP')
                            setModuleSN(item.label)

                        setRegister(item)
                    }} value={register}/>
                </FormGroup>
                
                <FormGroup>
                    <Label>Module <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <Select options={moduleOptions} onChange={item=>{
                        if(item.value=='QMP')
                            setModuleSN(register?register.label:'')
                        else
                            setModuleSN(item.moduleCount+1)

                        setModule(item)
                    }} value={module}/>
                </FormGroup>
                
                <FormGroup>
                    <Label>Module SN <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <Input disabled value={moduleSN} onChange={()=>{}} />
                </FormGroup>
                
                <FormGroup>
                    <Label>Start Date <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <DatePicker customInput={<Input/>} selected={startDate} dateFormat="yyyy/MM/dd" onChange={(date)=>{
                        setStartDate(date)
                    }}/>
                </FormGroup>
                
                <FormGroup>
                    <Label>End Date <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <DatePicker customInput={<Input/>} selected={endDate} dateFormat="yyyy/MM/dd" onChange={(date)=>{
                        setEndDate(date)
                    }}/>
                </FormGroup>
                
                <FormGroup style={{display:'flex',alignItems:'center',marginBottom:'20px'}}>
                    <Label>Is Active <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <Input style={{fontSize:'40px',marginLeft:'30px'}} checked={isActive} onChange={e=>{
                        setIsActive(e.target.checked)
                    }} type="checkbox"/>
                </FormGroup>
                <hr/>
                <Button color="success" style={{float:'right',paddingRight:'20px',paddingLeft:'20px'}} onClick={e=>addLicense(e)}>Add</Button>
            </Form>

        </ModalBody>
    </Modal>
}