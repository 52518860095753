import {React, useEffect, useState} from "react"
import DatePicker from "react-datepicker"
import Select from "react-select"
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap"
import Api, { formatDate } from "../../Shared"

export default function EditLicense ({toggle,refresh,isOpen,selectedRow,userRole}){

    const [module,setModule]=useState(null)
    const [startDate,setStartDate]=useState(null)
    const [endDate,setEndDate]=useState(null)
    const [moduleSN,setModuleSN]=useState('')
    const [registerSN,setRegisterSN]=useState(null)
    const [isActive,setIsActive]=useState(false)
    const [initialRegisterSN,setInitialRegisterSN]=useState('')
    const [initialModuleSN,setInitialModuleSN]=useState('')
    const [initialModule,setInitialModule]=useState('')
    const [moduleOptions,setModuleOptions]=useState([])
    const [registerOptions,setRegisterOptions]=useState([])

    useEffect(()=>{
        getRegisterOptions()
        getModuleOptions()
    },[])

    useEffect(()=>{
        if(selectedRow==null)
            return

        getRegisterOptions()
        getModuleOptions()

        setModule(moduleOptions.find(mo=>mo.value==selectedRow.module))
        setRegisterSN(registerOptions.find(ro=>ro.value==selectedRow.registerSN))
        setIsActive(selectedRow.isActive=='X')
        setModuleSN(selectedRow.moduleSN)
        setStartDate(selectedRow.startDate?new Date(selectedRow.startDate):null)
        setEndDate(selectedRow.endDate?new Date(selectedRow.endDate):null)
        setInitialRegisterSN(selectedRow.registerSN)
        setInitialModuleSN(selectedRow.moduleSN)
        setInitialModule(selectedRow.module)

    },[selectedRow])

    async function getRegisterOptions(){
        let res=await Api({method:'get_register_options'})

        if(res&&res.success)
            setRegisterOptions(res.register_options.map(ro=>({value:ro.registerSN,label:ro.registerSN})))

    }

    async function getModuleOptions(){
        let res=await Api({method:'get_module_options'})

        if(res&&res.success)
            setModuleOptions(res.module_options.map(ro=>({value:ro.id,label:ro.name,moduleCount:ro.moduleCount})))
    }

    async function editActivationCode(e){
        e.preventDefault()

        if(!(registerSN&&module&&startDate&&endDate&&moduleSN)){
            alert('All the fields must be filled')
            return
        }

        let res=await Api({method:'edit_license',
            startDate:formatDate(startDate),
            endDate:formatDate(endDate),
            initialRegisterSN,
            registerSN:registerSN.label,
            module:module.value,
            moduleSN,
            isActive:isActive?'X':'N',
            initialRegisterSN,
            initialModuleSN,
            initialModule
        })
        if(res&&res.success){
            toggle()
            refresh()
            clearData()
        }
        else
            alert('License couldn\'t edited. Error: \n'+res.error)
    }

    function clearData(){
        setStartDate(null)
        setEndDate(null)
        setModule(null)
        setRegisterSN(null)
        setIsActive(false)
    }

    return <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>
            Edit Activation Code
        </ModalHeader>
        <ModalBody>
            <Form>
                <FormGroup>
                    <Label>Register SN <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <Select options={registerOptions} onChange={item=>{
                        if(module&&module.value=='QMP')
                            setModuleSN(item.label)

                        setRegisterSN(item)
                    }} value={registerSN}/>
                </FormGroup>
                
                <FormGroup>
                    <Label>Module <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <Select options={moduleOptions} onChange={item=>{
                        if(item.value=='QMP')
                            setModuleSN(registerSN?registerSN.label:'')
                        else
                            setModuleSN(item.moduleCount+1)

                        setModule(item)
                    }} value={module}/>
                </FormGroup>
                
                <FormGroup>
                    <Label>Module SN <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <Input disabled={!["1"].includes(userRole)} value={moduleSN} onChange={e=>{
                        setModuleSN(e.target.value)
                    }} />
                </FormGroup>
                
                <FormGroup>
                    <Label>Start Date <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <DatePicker customInput={<Input/>} selected={startDate} dateFormat="yyyy/MM/dd" onChange={(date)=>{
                        setStartDate(date)
                    }}/>
                </FormGroup>
                
                <FormGroup>
                    <Label>End Date <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <DatePicker customInput={<Input/>} selected={endDate} dateFormat="yyyy/MM/dd" onChange={(date)=>{
                        setEndDate(date)
                    }}/>
                </FormGroup>
                
                <FormGroup style={{display:'flex',alignItems:'center',marginBottom:'20px'}}>
                    <Label>Is Active <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <Input style={{fontSize:'40px',marginLeft:'30px'}} checked={isActive} onChange={e=>{
                        setIsActive(e.target.checked)
                    }} type="checkbox"/>
                </FormGroup>
                <hr/>

                <Button color="success" style={{float:'right',paddingRight:'20px',paddingLeft:'20px'}} onClick={e=>editActivationCode(e)}>Edit</Button>
            </Form>

        </ModalBody>
    </Modal>
}