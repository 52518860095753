import { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, FormGroup, Input, Label } from "reactstrap";
import Api from "../Shared";
import abstract_background from "../img/abstract_background.webp"

export default function Account(){

    const [userName,setUserName]=useState('')
    const [userRoleName,setUserRoleName]=useState('')
    const [oldPassword,setOldPassword]=useState('')
    const [newPassword,setNewPassword]=useState('')

    useEffect(()=>{
        getUserInfo()
    },[])

    async function getUserInfo(){
        const res = await Api({method:'get_detailed_user_info'})
        if(res){
            setUserRoleName(res.role)
            setUserName(res.name)
        }
        return
    }

    async function resetPassword(){

        if(!window.confirm('Are you sure to reset your password? It will be set as\n\' User Name \' + \' % \' + \' Current Year \''))
            return

        const res=await Api({method:'reset_password'})

        if(res&&res.success)
            alert('Password successfuly reset')
        else
            alert('Password couldn\' t reset. Error: '+res.error)
    }

    async function changePassword(){

        if(!window.confirm('Are you sure to change your password?'))
            return

        const res=await Api({method:'change_password',oldPassword,newPassword})

        if(res&&res.success){
            alert('Password successfuly changed.')
            setOldPassword('')
            setNewPassword('')
        }
        else
            alert('Password couldn\' t changed. Error: '+res.error)
    }

    return <div className="accountContainer">
        <img className="accountBackgroundImage" src={abstract_background}/>
        <div className="customCard">
            <div className="customCardHeader">Account Info</div>
            <div className="customCardBody">
                <span style={{fontSize: 'larger',fontWeight:'bolder'}}>User Name:</span> {userName}
                <hr/>
                <span style={{fontSize: 'larger',fontWeight:'bolder'}}>User Role:</span> {userRoleName}
            </div>
            <div className="customCardFooter">
                <div className="customCardFooterButton" onClick={()=>{resetPassword()}}>
                    <span style={{zIndex:0}}>Reset Password</span>
                </div>
            </div>
        </div>

        <div className="customCard">
            <div className="customCardHeader">Change Password</div>
            <div className="customCardBody">
                <input
                    type='password'
                    style={{width:'100%',padding:'10px 15px'}}
                    value={oldPassword}
                    onChange={e=>{ setOldPassword(e.target.value) }}
                    placeholder='Old Password'/>
                <hr/>
                <input
                    type='password'
                    style={{width:'100%',padding:'10px 15px'}}
                    value={newPassword}
                    onChange={e=>{ setNewPassword(e.target.value) }}
                    placeholder='New Password'/>
            </div>
            <div className="customCardFooter">
                <div className="customCardFooterButton" onClick={()=>{ changePassword() }}>
                    <span style={{zIndex:0}}>
                            Send
                    </span>
                </div>
            </div>
        </div>
    </div>
}