import styled from "styled-components";
            
export const Styles=styled.div`
padding: 1rem;
overflow:hidden !important;

thead{
  position:sticky;
  top:0;
}

table {
  background-color:#ffffff;
  border-spacing: 0;
  border: none;
  color:#000000;
  width:100%;
  tr {
    :last-child {
      td {
        border-bottom: 0 !important;
      }
    }

    :nth-child(even) {
      background-color:#212b3730;
      td.options:last-child{
          background-color:#d5d7d9;
      }
    }

    :nth-child(odd) {

      td.options:last-child{
          background-color:#ffffff;
      }
    }

    :hover{
      background-color:#212b3760;
      // color:#ffffff;
      cursor:pointer;

      td{
        border-right: 2px solid white;
      }

      td:nth-last-child(2){
        border-right:2px solid #212b37;
      }
    }

  }

  td {
    margin: 0;
    padding: 0.5rem;
    border-right: 2px solid #212b37;

    :last-child {
      border-right: 0 !important;
    }
  }

  th{
    margin: 0;
    background-color:#212b37;
    color:#ffffff;
    padding: 0.5rem;
    border-right: 2px solid white;

    :last-child {
      border-right: 0;
    }

  }

}`