import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { BiSearchAlt } from 'react-icons/bi'
import { IoMdRefresh } from 'react-icons/io'
import { TiDocumentAdd, TiRefreshOutline } from 'react-icons/ti'
import Table from './Table'
import {Styles} from './Styles'
import NewLicense from './Modals/NewLicense'
import EditLicense from './Modals/EditLicense'
import Api, { formatDate } from '../Shared'
import { FaEraser, FaSkullCrossbones } from 'react-icons/fa'
import { FiChevronsDown, FiChevronsUp, FiEdit } from 'react-icons/fi'
import NewUser from './Modals/NewUser'
import EditUser from './Modals/EditUser'
import { Input, InputGroup, InputGroupText } from 'reactstrap'
import ReactSelect from 'react-select'
import { RiArrowUpDownFill } from 'react-icons/ri'
import ReactDatePicker from 'react-datepicker'

export default function Users(){

  const [newModalOpen,setNewModalOpen]=useState(false)
  const [currentPage,setCurrentPage]=useState(1)
  const [totalPages,setTotalPages]=useState(1)
  const [loading,setLoading]=useState(true)
  const [userData,setUserData]=useState([])
  const [rowsPerPage,setRowsPerPage]=useState(10)
  const [editRow,setEditRow]=useState(null)
  const [roleOptions,setRoleOptions]=useState([])
  const [userNameSearch,setUserNameSearch]=useState('')
  const [userRoleSearch,setUserRoleSearch]=useState(null)
  const [triggerClear,setTriggerClear]=useState(false)
  const [asc,setAsc]=useState(false)
  const [desc,setDesc]=useState(false)
  const [lastLoginSearch,setLastLoginSearch]=useState(null)

  async function hardDeleteUser(id){
      const res = await Api({method:'hard_delete_user',id})

      if(res&&res.success)
          getUsers()
      else
          alert(res.error)
  }
  
  async function resetPassword(id){
      const res = await Api({method:'reset_user_password',id})

      if(res&&res.success)
          getUsers()
      else
          alert(res.error)
  }

  const isRowsPerPageMount=useRef(false)
  useEffect(()=>{

    // checks if request is from first render
    if(!isRowsPerPageMount.current){
      isRowsPerPageMount.current=true
      return
    }

    setCurrentPage(1)
    getUsers()
  },[rowsPerPage])

  const isCurrentPageMount=useRef(false)
  useEffect(()=>{
      
      if(!isCurrentPageMount.current){
          isCurrentPageMount.current=true
          return
      }

      if(totalPages!=0 && currentPage>totalPages)
        setCurrentPage(totalPages)
      else
        getUsers()
    },[currentPage])
  
  async function setRows(rows){
      const res = await Api({method:'set_rows_per_page',rows})

      if(res&&res.success)
          setRowsPerPage(rows)
      else
          alert('Couldn\' t change rows per page. Error:\n'+res.error)

      return
  }

  async function get_user_info(){
      const res = await Api({method:'get_user_info'})
      
      if(res&&res.rowsPerPage)
        setRowsPerPage(res.rowsPerPage)

      return
  }

  function clearSearchAndRefresh(){
    setUserNameSearch('')
    setUserRoleSearch(null)
    setAsc('')
    setDesc('')
    setTriggerClear(!triggerClear)
  }

  useEffect(()=>{
    if(asc)
      getUsers()
  },[asc])
  
  useEffect(()=>{
    if(desc)
      getUsers()
  },[desc])

  function setAscDesc(id){
    setCurrentPage(1)
    if(asc==id){
      setDesc(id)
      setAsc('')
    }
    else if (desc==id){
      setAsc(id)
      setDesc('')
    }
    else{
      setAsc(id)
      setDesc('')
    }
  }

  function getIconAscDesc(id){
    if(asc==id)
      return <FiChevronsUp size='18' color='#42ba96' style={{float:'right'}}/>
    else if (desc==id)
      return <FiChevronsDown size='18' color='#d3002a' style={{float:'right'}}/>

    return <RiArrowUpDownFill size='18' color='#ffffff' style={{float:'right'}}/>
  }

  const isTriggerClearMount=useRef(false)
  useEffect(()=>{

    if(!isTriggerClearMount.current){
      isTriggerClearMount.current=true
      return
    }
    getUsers()
  },[triggerClear])

  const columns=React.useMemo(()=>[
      {
          Header:({column:{id}})=><div onClick={()=>{
            setAscDesc(id)
          }}>Role {getIconAscDesc(id)}</div>,
          accessor:'userRoleName'
      },
      {
          Header:({column:{id}})=><div onClick={()=>{
            setAscDesc(id)
          }}>User Name {getIconAscDesc(id)}</div>,
          accessor:'name'
      },
      {
          Header:({column:{id}})=><div onClick={()=>{
            setAscDesc(id)
          }}>Last Login {getIconAscDesc(id)}</div>,
          accessor:'lastLogin'
      },
      {
          className:'options',
          Header:'Options',
          width:'10%',
          Cell:({row:{original:{ID}},row:{original}})=> <div className='customButtonGroup'>
              <div
                  onClick={()=>{
                      if(!window.confirm('Do you really want to delete this row?'))
                          return
                      
                      hardDeleteUser(ID)
                  }}
                  style={{backgroundColor:'#084298',width:'30px',height:'30px',color:'white'}}
                  title='Hard Delete' className='customButton'>
                      <FaSkullCrossbones size={15}/>
              </div>
              <div
                  onClick={()=>{setEditRow(original)}}
                  style={{backgroundColor:'#f19241',width:'30px',height:'30px',color:'white'}}
                  className='customButton'>
                      <FiEdit size={15}/>
              </div>
              <div
                  onClick={()=>{
                      if(!window.confirm('Do you really want to reset this user\' s password?'))
                          return
                      
                      resetPassword(ID)
                  }}
                  style={{backgroundColor:'#0d6efd',width:'30px',height:'30px',color:'white'}}
                  className='customButton'>
                      <FaEraser size={15}/>
              </div>
        </div>
      }
  ])

  useEffect(()=>{
      get_user_info()
      getUsers()
      getRoleOptions()
  },[])

  async function getUsers(){
      setLoading(true)
      let res= await Api({
          method:'get_users',
          currentPage,
          rowsPerPage,
          userRoleSearch:userRoleSearch?userRoleSearch.value:'',
          userNameSearch,
          lastLoginSearch:lastLoginSearch?formatDate(lastLoginSearch):null,
          asc,
          desc
      })

      if(res&&res.users){
          setUserData(res.users)
          setTotalPages(Math.ceil(res.row_count/rowsPerPage))
          setLoading(false)
      }
      else
          alert(res.error)
  }
  
  async function getRoleOptions(){
    let res=await Api({method:'get_role_options'})

    if(res&&res.success)
        setRoleOptions(res.role_options.map(ro=>({value:ro.id,label:ro.name})))
  }

  const LastLoginSearch = forwardRef(({ value, onClick,onChange }, ref) => (
    <InputGroup>
      <InputGroupText>Last Login</InputGroupText>
      <Input ref={ref} value={value} onChange={onChange} onClick={onClick} placeholder='...'/>
    </InputGroup>
  ));

  return <>
    <Styles>
      <div className='dataHeader'>
        <div className='searchKit'>
          <div className='column'>
            <InputGroup>
              <InputGroupText>User Name</InputGroupText>
              <Input
                value={userNameSearch}
                onChange={(e)=>{ setUserNameSearch(e.target.value) }}
                placeholder='...'
              />
            </InputGroup>
            
            <ReactDatePicker
                    customInput={<LastLoginSearch />}
                    isClearable
                    selected={lastLoginSearch}
                    dateFormat="yyyy-MM-dd"
                    onChange={e=> {setLastLoginSearch(e)}}/>
            </div>
          <div className='column'>
            
            <ReactSelect
              styles={{
                control:(provided,state)=>{
                  return {
                    ...provided,
                    border:"3px solid black"}
                }
              }}
              options={roleOptions}
              value={userRoleSearch}
              placeholder={'User Role'}
              onChange={item=>{setUserRoleSearch(item)}}
              isClearable
            />
            {/* <div style={{margin:'14px'}}/> */}
          </div>
        </div>
        <div className='buttonContainer'>
          <div className='pageBox'>
            <select className='pageSelect' type='number' value={rowsPerPage} onChange={e=>{
              let {value}=e.target
              if(value>0){
                setRows(value)
              }
            }}>
              <option>10</option>
              <option>25</option>
              <option>50</option>
            </select>
            <div className='pageText'>Rows Per Page</div>
          </div>
          <div
              style={{backgroundColor:'#d3002a'}}
              onClick={()=>{ setNewModalOpen(true) }}
              className='customButton'>
                  <TiDocumentAdd size={50}/>
          </div>
            
          <div style={{backgroundColor:'#42ba96'}} onClick={async ()=>{
            let el=document.getElementById('scroll')
            el.scrollTo(0,0)
            clearSearchAndRefresh()
          }} className='customButton'><IoMdRefresh size={50}/></div>
          
          <div style={{backgroundColor:'#f19241'}}
          onClick={()=>{
              let el=document.getElementById('scroll')
              el.scrollTo(0,0)
              currentPage==1?getUsers():setCurrentPage(1)
          }}
          className='customButton'>
              <BiSearchAlt size={50}/>
          </div>
        
        </div>
      </div>
      <hr/>
      <div className='contentContainer'>
        {loading&&<div className='loading'>Loading...</div>}
        <div className='dataContainer' id='scroll'>
          <Table columns={columns} data={userData}/>
        </div>
      </div>
      
    <div className='dataFooter'>
        <div className='buttonSetLeft'>
          <button disabled={!(currentPage>1)} onClick={()=>{
            if(currentPage>1)
              setCurrentPage(1)
            }}>{'|<'}</button>
          <button disabled={!(currentPage>1)} onClick={()=>{
            if(currentPage>1)
              setCurrentPage(currentPage-1)
            }}>{'<'}</button>
        </div>
          <div className='currentPage'>
            <input
                style={{ width:(20+currentPage.toString().length*10)+'px' }}
                type='number'
                size={2}
                value={currentPage}
                onChange={e=>{
                    let {value}=e.target
                    
                    if(value>0 && value<999){
                        setCurrentPage(value)
                    }
                }}/>/ {totalPages}</div>
        <div className='buttonSetRight'>
          <button disabled={!(currentPage<totalPages)} onClick={()=>{
              if(currentPage<totalPages)
                setCurrentPage(currentPage+1)
          }}>{'>'}</button>
          <button disabled={!(currentPage<totalPages)} onClick={()=>{
              if(currentPage<totalPages)
                setCurrentPage(totalPages)
          }}>{'>|'}</button>
        </div>
    </div>
    </Styles>
    <NewUser refresh={()=>getUsers()} isOpen={newModalOpen} toggle={()=>setNewModalOpen(!newModalOpen)}/>
    <EditUser refresh={()=>getUsers()} isOpen={editRow!=null} selectedRow={editRow} toggle={()=>setEditRow(null)}/>
  </>
}