import {React, useEffect, useState} from "react"
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap"
import Api, { formatDate } from "../../Shared"
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

export default function NewUser ({refresh,isOpen,toggle}){

    const [roleOptions,setRoleOptions]=useState([])

    const [userName, setUserName]=useState()
    const [userPassword, setUserPassword]=useState()
    const [userRole, setUserRole]=useState(null)

    useEffect(()=>{
        if(isOpen==true)
            getRoleOptions()
    },[isOpen])

    async function getRoleOptions(){
        let res=await Api({method:'get_role_options'})

        if(res&&res.success)
            setRoleOptions(res.role_options.map(ro=>({value:ro.id,label:ro.name})))
    }

    async function addUser(e){
        e.preventDefault()

        if(!(userName&&userPassword&&userRole)){
            alert('All the fields must be filled')
            return
        }

        let res=await Api({method:'add_user',
            userName,
            userPassword,
            userRole:userRole.value
        })

        if(res&&res.success){
            toggle()
            refresh()
            clearData()
        }
        else
            alert('User couldn\'t added. Error: \n'+res.error)
    }

    function clearData(){
        setUserRole(null)
        setUserName('')
        setUserPassword('')
    }

    useEffect(()=>{
        if(isOpen)
            clearData()
    },[isOpen])

    return <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>
            Add New User
        </ModalHeader>
        <ModalBody>

            <Form>
                <FormGroup>
                    <Label>Name <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <Input
                        type="text"
                        onChange={e=>{
                            setUserName(e.target.value)
                        }}
                        value={userName}/>
                </FormGroup>
                
                <FormGroup>
                    <Label>Password <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <Input
                        type="text"
                        onChange={e=>{
                            setUserPassword(e.target.value)
                        }}
                        value={userPassword}/>
                </FormGroup>
                
                <FormGroup>
                    <Label>User Role <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <Select
                        options={roleOptions}
                        onChange={item=>{
                            setUserRole(item)
                        }}
                        value={userRole}/>
                </FormGroup>

                <Button color="success" style={{float:'right',paddingRight:'20px',paddingLeft:'20px'}} onClick={e=>addUser(e)}>Add</Button>
            </Form>

        </ModalBody>
    </Modal>
}