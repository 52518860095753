import {React, useEffect, useState} from "react"
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap"
import Api from "../../Shared"

export default function EditActivationCode ({toggle,refresh,isOpen,selectedRow}){

    const [activationCode,setActivationCode]=useState('')
    const [dbc,setDbc]=useState('')
    const [customer,setCustomer]=useState('')
    const [registerSN,setRegisterSN]=useState('')
    const [initialRegisterSN,setInitialRegisterSN]=useState('')

    useEffect(()=>{
        if(selectedRow==null)
            return
        setActivationCode(selectedRow.activationCode)
        setDbc(selectedRow.dbc)
        setCustomer(selectedRow.customer)
        setRegisterSN(selectedRow.registerSN)
        setInitialRegisterSN(selectedRow.registerSN)
    },[selectedRow])

    async function editActivationCode(e){
        e.preventDefault()

        if(!(activationCode&&customer&&registerSN)){
            alert('Fields must be filled.')
            return
        }

        let res=await Api({method:'edit_activation_code',
            activationCode,
            dbc,
            customer,
            registerSN,
            initialRegisterSN
        })
        if(res&&res.success){
            toggle()
            refresh()
            clearData()
        }
        else
            alert('Activation code couldn\'t edited. Error: \n'+res.error)
    }

    function clearData(){
        setActivationCode('')
        setDbc('')
        setCustomer('')
        setRegisterSN('')
    }

    return <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>
            Edit Activation Code
        </ModalHeader>
        <ModalBody>

            <Form>
                <FormGroup>
                    <Label>Register SN <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <Input value={registerSN} onChange={e=>setRegisterSN(e.target.value)}/>
                </FormGroup>

                <FormGroup>
                    <Label>Activation Code <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <Input value={activationCode} onChange={e=>setActivationCode(e.target.value)}/>
                </FormGroup>

                <FormGroup>
                    <Label>DBC</Label>
                    <Input value={dbc} onChange={e=>setDbc(e.target.value)}/>
                </FormGroup>

                <FormGroup>
                    <Label>Customer <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <Input value={customer} onChange={e=>setCustomer(e.target.value)}/>
                </FormGroup>

                <Button color="success" style={{float:'right',paddingRight:'20px',paddingLeft:'20px'}} onClick={e=>editActivationCode(e)}>Edit</Button>
            </Form>

        </ModalBody>
    </Modal>
}