import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useLocation } from "react-router-dom";
import './styles/App.css';
import Login from "./components/Login";
import logo from "./img/quorion-delta-logo.png"
import axios from 'axios';
import Licenses from './components/Licenses'
import ActivationCodes from './components/ActivationCodes'
import Api from './Shared';
import Users from './components/Users';
import Account from './components/Account';

const API_PATH="https://qlicense.de/api/panel/index.php";
// const API_PATH="http://localhost:80/index.php";

function App(){

  const [isValid,setIsValid]=useState(false)
  const [userRole,setUserRole]=useState()
  const [userName,setUserName]=useState()

  const location =useLocation()

  async function setValidation(){
    await axios({
      method:'post',
      url:`${API_PATH}`,
      headers:{'content-type':'application/json'},
      data:{method:"is_valid",access_token:sessionStorage.getItem('token')}
    }).then((res)=>{
      setIsValid(res.data.is_valid)
    })
  }
  
  async function getUserRole(){
    const res = await Api({method:'get_user_info'})
    if(res&&res.userRole){
      setUserRole(res.userRole)
      setUserName(res.userName)
    }
    return
  }

  useEffect(()=>{
    setValidation()
    getUserRole()
    return
  },[])

  function logOut(){
    sessionStorage.setItem('token','')
    window.location.href='/Login'
  }

  let selectedLinkStyle={
    fontWeight:'bolder',
    fontSize:'larger',
    textDecoration:'underline'
  }

  return isValid ? <div>
            <div className='customNav'>
              <div className='content'>
                <Link
                  style={location.pathname=='/Activation'?selectedLinkStyle:{}}
                  location='/' to='/Activation'
                  className='customNavItem'>
                    Activation Codes
                </Link>
                <Link
                  style={location.pathname=='/Licenses'?selectedLinkStyle:{}}
                  location='/'
                  to='/Licenses'
                  className='customNavItem'>
                    Licenses
                </Link>
                {["1"].includes(userRole)&&
                  <Link
                    style={location.pathname=='/Users'?selectedLinkStyle:{}}
                    location='/'
                    to='/Users'
                    className='customNavItem'>
                      Users
                  </Link>}
                <Link
                  style={location.pathname=='/Account'?selectedLinkStyle:{}}
                  location='/'
                  to='/Account'
                  className='customNavItem'>
                    {userName}
                </Link>
                <a className='customNavItem' onClick={()=>logOut()}>Log Out</a>
              </div>
              <div className='navLogo'>
                <img src={logo} alt='logo'/>
              </div>
            </div>
            <Routes>
              <Route path="/Activation" caseSensitive={false} element={<ActivationCodes userRole={userRole}/>} />
              <Route path="/Licenses" caseSensitive={false} element={<Licenses/>} />
              <Route path="/Users" caseSensitive={false} element={<Users/>} />
              <Route path="/Account" caseSensitive={false} element={<Account/>} />
            </Routes>
          </div>
          :
          <Routes>
            <Route path="*" caseSensitive={false} element={<Login/>} />
          </Routes>
}

export default App;