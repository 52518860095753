import axios from "axios"
const API_PATH="https://qlicense.de/api/panel/index.php";
// const API_PATH="http://localhost:80/index.php";

export default async function Api(request){

    let access_token=sessionStorage.getItem('token')
    let response

    await axios({
        method:'post',
        url:`${API_PATH}`,
        headers:{'content-type':'application/json'},
        data:{...request,access_token}
    }).then((res)=>{
        response=res.data
        if(response.success=='False Token'){
            sessionStorage.setItem('token',null)
            if(window.location.pathname!='/Login')
                window.location.href='/Login'
        }
    })

    return response
}

export function formatDate(date){
    let year=date.getFullYear()
    let month=date.getMonth()+1
    let day=date.getDate()
    return year+'-'+((month)>10?month:'0'+(month))+'-'+((day)>10?day:'0'+(day))
}