import React, {useState} from 'react';
import { Form, FormGroup, Input, Card, CardHeader, CardBody, CardTitle, CardFooter, Button } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from "../img/img.png";
// import image1 from '../img/1.webp';
// import image2 from '../img/2.webp';
// import image3 from '../img/3.webp';
// import image4 from '../img/4.webp';
// import image5 from '../img/5.webp';
import axios from 'axios';
const API_PATH="https://qlicense.de/api/panel/index.php";
// const API_PATH="http://localhost:80/index.php";


function Login(){
    const [userName,setUserName]=useState('')
    const [password,setPassword]=useState('')


    function handleSubmit(e){
        e.preventDefault()
        
        axios({
            method:'post',
            url:`${API_PATH}`,
            headers:{'content-type':'application/json'},
            data:{userName, password, method:"login"}
        }).then((res)=>{
            
            if(res.data.connection==='success'){
                sessionStorage.setItem('token',res.data.token)
                window.location.href='/Activation'
            }
            else
                alert('Hatalı giriş yaptınız')
        })
    }

    return <div className="login-div">
                <div className="login-card-div" >
                    <Card className="login-card">
                        <CardHeader>
                            <img className="logo-image" src={logo} alt="Logo" />
                            <CardTitle>
                                QUORiON <br/>
                                License Service
                            </CardTitle>

                        </CardHeader>
                        <CardBody>
                            <Form action='https://qlicense.de'>
                                <FormGroup>
                                
                                    <Input
                                        id="qlicense_username"
                                        name="email"
                                        placeholder="User Name"
                                        type="email"
                                        value={userName}
                                        onChange={e=>{
                                            setUserName(e.target.value)
                                        }}
                                    />
                                    <br/>
                                    <Input
                                        id="qlicense_password"
                                        name="password"
                                        placeholder="Password"
                                        type="password"
                                        value={password}
                                        onChange={(e)=>{
                                            setPassword(e.target.value)
                                        }}
                                    />
                                    <br/>
                                    <Button type='submit' color='success' onClick={e=>handleSubmit(e)} className="login-button">
                                    Login
                                    </Button>
                                </FormGroup>
                            </Form>
                        </CardBody>
                        <CardFooter>
                            QUORiON Business Solutions GmbH
                        </CardFooter>
                    </Card>
                </div>
            </div>

}

export default Login;
