import {React, useEffect, useState} from "react"
import DatePicker from "react-datepicker"
import Select from "react-select"
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap"
import Api, { formatDate } from "../../Shared"

export default function EditUser ({toggle,refresh,isOpen,selectedRow}){

    const [ID,setID]=useState(null)
    const [userName,setUserName]=useState('')
    const [userRole,setUserRole]=useState(null)
    const [roleOptions,setRoleOptions]=useState([])

    useEffect(()=>{
        getRoleOptions()
    },[])

    useEffect(()=>{
        if(selectedRow==null)
            return

        getRoleOptions()

        setUserRole(roleOptions.find(ro=>ro.value==selectedRow.userRole))
        setID(selectedRow.ID)
        setUserName(selectedRow.name)
    },[selectedRow])

    async function getRoleOptions(){
        let res=await Api({method:'get_role_options'})

        if(res&&res.success)
            setRoleOptions(res.role_options.map(ro=>({value:ro.id,label:ro.name})))
    }

    async function editRole(e){
        e.preventDefault()

        if(!(userName&&userRole)){
            alert('All the fields must be filled')
            return
        }

        let res=await Api({method:'edit_user',
            ID,
            userName,
            userRole:userRole.value
            })

        if(res&&res.success){
            toggle()
            refresh()
            clearData()
        }
        else
            alert('User couldn\'t edited. Error: \n'+res.error)
    }

    function clearData(){
        setUserName('')
        setUserRole(null)
    }

    return <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>
            Edit User
        </ModalHeader>
        <ModalBody>
            <Form>
                <FormGroup>
                    <Label>Name <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <Input
                        type="text"
                        onChange={e=>{
                            setUserName(e.target.value)
                        }}
                        value={userName}/>
                </FormGroup>
                
                <FormGroup>
                    <Label>User Role <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <Select
                        options={roleOptions}
                        onChange={item=>{
                            setUserRole(item)
                        }}
                        value={userRole}/>
                </FormGroup>

                <Button color="success" style={{float:'right',paddingRight:'20px',paddingLeft:'20px'}} onClick={e=>editRole(e)}>Add</Button>
            </Form>

        </ModalBody>
    </Modal>
}