import {React, useState} from "react"
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap"
import Api from "../../Shared"

export default function NewActivationCode ({toggle,refresh,isOpen}){

    const [activationCode,setActivationCode]=useState('')
    const [DBC,setDBC]=useState('')
    const [customer,setCustomer]=useState('')
    const [register,setRegister]=useState('')

    async function addActivationCode(e){
        e.preventDefault()
        if(activationCode.includes('&')){
            
            alert('Activation code cannot include \'&\'')
            return
        }
        if(!(activationCode&&customer&&register)){
            alert('Fields must be filled.')
            return
        }

        let res=await Api({method:'add_activation_code',activationCode,DBC,customer,register})
        if(res&&res.success){
            toggle()
            refresh()
            clearData()
        }
        else
            alert('Activation code couldn\'t added. Error: \n'+res.error)
    }

    function clearData(){
        setActivationCode('')
        setDBC('')
        setCustomer('')
        setRegister('')
    }

    return <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>
            Add New Activation Code
        </ModalHeader>
        <ModalBody>

            <Form>
                <FormGroup>
                    <Label>Register SN <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <Input value={register} onChange={e=>setRegister(e.target.value)}/>
                </FormGroup>

                <FormGroup>
                    <Label>Activation Code <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <Input value={activationCode} onChange={e=>setActivationCode(e.target.value)}/>
                </FormGroup>

                <FormGroup>
                    <Label>DBC</Label>
                    <Input value={DBC} onChange={e=>setDBC(e.target.value)}/>
                </FormGroup>

                <FormGroup>
                    <Label>Customer <span style={{color:'#d3002a',fontWeight:'bold'}}>*</span></Label>
                    <Input value={customer} onChange={e=>setCustomer(e.target.value)}/>
                </FormGroup>

                <Button color="success" style={{float:'right',paddingRight:'20px',paddingLeft:'20px'}} onClick={e=>addActivationCode(e)}>Add</Button>
            </Form>

        </ModalBody>
    </Modal>
}