import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { BiSearchAlt } from 'react-icons/bi'
import { IoMdRefresh } from 'react-icons/io'
import { TiDocumentAdd, TiRefreshOutline } from 'react-icons/ti'
import { Input, InputGroup, InputGroupText } from 'reactstrap'
import Api, { formatDate } from '../Shared'
import Table from './Table'
import {Styles} from './Styles'
import { FiChevronsDown, FiChevronsUp, FiChevronUp, FiEdit } from 'react-icons/fi'
import { HiOutlineTrash } from 'react-icons/hi'
import { FaSkullCrossbones } from 'react-icons/fa'
import { RiArrowUpDownFill } from 'react-icons/ri'
import NewLicense from './Modals/NewLicense'
import DatePicker from 'react-datepicker'
import EditLicense from './Modals/EditLicense'

export default function Licenses(){

  let [licenseData,setLicenseData]=useState([])
  let [newModalOpen,setNewModalOpen]=useState(false)
  let [loading,setLoading]=useState(true)
  let [rowsPerPage,setRowsPerPage]=useState(10)
  let [currentPage,setCurrentPage]=useState(1)
  let [totalPages,setTotalPages]=useState(0)
  let [asc,setAsc]=useState(0)
  let [desc,setDesc]=useState(0)
  let [showDeleted,setShowDeleted]=useState(0)
  let [editRow,setEditRow]=useState(null)
  let [userRole,setUserRole]=useState()

  // search variables
  let [registerSearch,setRegisterSearch]=useState('')
  let [moduleSearch,setModuleSearch]=useState('')
  let [isActiveSearch,setIsActiveSearch]=useState('')
  let [startDateSearch,setStartDateSearch]=useState(null)
  let [endDateSearch,setEndDateSearch]=useState(null)
  let [isDeletedSearch,setIsDeletedSearch]=useState(false)

  // triggers when all the values are set in clearSearchAndRefresh function
  let [triggerClear,setTriggerClear]=useState(false)

  // triggers when refresh button gets clicked
  function clearSearchAndRefresh(){
    setRegisterSearch('')
    setModuleSearch('')
    setIsActiveSearch('')
    setStartDateSearch(null)
    setEndDateSearch(null)
    setAsc('')
    setDesc('')
    setCurrentPage(1)
    setTriggerClear(!triggerClear)
  }

  async function getUserInfo(){
    const res = await Api({method:'get_user_info'})
    if(res&&res.userRole){
      setUserRole(res.userRole)
      res.rowsPerPage&&setRowsPerPage(res.rowsPerPage)
    }
    return
  }

  useEffect(()=>{
    getLicenses()
  },[triggerClear])

  async function getLicenses(){
    setLoading(true)

    let startDateSearchInstance=null
    if(startDateSearch)
      startDateSearchInstance=formatDate(new Date(startDateSearch))
    
    let endDateSearchInstance=null
    if(endDateSearch)
      endDateSearchInstance=formatDate(new Date(endDateSearch))

    let response=await Api({method:'get_licenses',
      registerSearch,
      moduleSearch,
      isActiveSearch,
      startDateSearch:startDateSearchInstance,
      endDateSearch:endDateSearchInstance,
      isDeletedSearch,
      currentPage,
      rowsPerPage,
      asc,
      desc
    })
    if(response.success&&response.success===true){
      setLicenseData(response.licenses)
      setTotalPages(Math.ceil(response.row_count/rowsPerPage))
      setShowDeleted(response.show_deleted)
    }
    else{
      alert('Lisans verileri yüklenemedi.\nHata Bilgisi:\n'+(response.error?response.error:'Sunucudan yanıt alınamadı.'))
      setLicenseData([])
    }
  }

  useEffect(()=>{
    getUserInfo()
  },[])

  const isDeletedSearchMount=useRef(false)
  useEffect(()=>{

    // checks if request is from first render
    if(!isDeletedSearchMount.current){
      isDeletedSearchMount.current=true
      return
    }

    getLicenses()
  },[isDeletedSearch])

  const isRowsPerPageMount=useRef(false)
  useEffect(()=>{

    // checks if request is from first render
    if(!isRowsPerPageMount.current){
      isRowsPerPageMount.current=true
      return
    }

    getLicenses()
  },[rowsPerPage])

  useEffect(()=>{
    if(licenseData!=[])
      setLoading(false)
  },[licenseData])
  
  useEffect(()=>{
    if(asc)
      getLicenses()
  },[asc])
  
  useEffect(()=>{
    if(desc)
      getLicenses()
  },[desc])

  useEffect(()=>{
    if(totalPages!=0 && currentPage>totalPages)
      setCurrentPage(totalPages)
    else
      getLicenses()
  },[currentPage])

  async function deleteLicense(registerSN,moduleSN,module){
    let res=await Api({method:'delete_license',registerSN,moduleSN,module})
    if(res.success)
      getLicenses()
    else
      alert('Selected row couldn\' t deleted. Error Message:\n'+res.error)
  }

  async function hardDeleteLicense(registerSN,moduleSN,module){

    if(!window.confirm('Do you really delete this row from database?'))
      return

    let res=await Api({method:'hard_delete_license',registerSN,moduleSN,module})
    if(res.success)
      getLicenses()
    else
      alert('Selected row couldn\' t deleted. Error Message:\n'+res.error)
  }

  // send db about users rows column
  async function setRows(rows){
    let res=await Api({method:'set_rows_per_page',rows})
    if(res.success)
      setRowsPerPage(rows)
    else
      alert('Couldn\' t change rows per page. Error:\n'+res.error)
  }

  async function recoverLicense(registerSN,moduleSN,module){
    let res=await Api({method:'recover_license',registerSN,moduleSN,module})
    if(res.success)
      getLicenses()
    else
      alert('Selected row couldn\' t deleted. Error Message:\n'+res.error)
  }

  function setAscDesc(id){
    setCurrentPage(1)
    if(asc==id){
      setDesc(id)
      setAsc('')
    }
    else if (desc==id){
      setAsc(id)
      setDesc('')
    }
    else{
      setAsc(id)
      setDesc('')
    }
  }

  function getIconAscDesc(id){
    if(asc==id)
      return <FiChevronsUp size='18' color='#42ba96' style={{float:'right'}}/>
    else if (desc==id)
      return <FiChevronsDown size='18' color='#d3002a' style={{float:'right'}}/>

    return <RiArrowUpDownFill size='18' color='#ffffff' style={{float:'right'}}/>
  }

  const columns=React.useMemo(
    ()=>[{
      Header:({column:{id}})=><div onClick={()=>{
        setAscDesc(id)
      }}>Register SN {getIconAscDesc(id)}</div>,
      accessor:'registerSN',
    },{
      Header:({column:{id}})=><div onClick={()=>{
        setAscDesc(id)
      }}>Module Name {getIconAscDesc(id)}</div>,
      accessor:'moduleName'
    },{
      Header:({column:{id}})=><div onClick={()=>{
        setAscDesc(id)
      }}>Module SN {getIconAscDesc(id)}</div>,
      accessor:'moduleSN'
    },{
      Header:({column:{id}})=><div onClick={()=>{
        setAscDesc(id)
      }}>Start Date {getIconAscDesc(id)}</div>,
      accessor:'startDate'
    },{
      Header:({column:{id}})=><div onClick={()=>{
        setAscDesc(id)
      }}>End Date {getIconAscDesc(id)}</div>,
      accessor:'endDate'
    },{
      Header:({column:{id}})=><div onClick={()=>{
        setAscDesc(id)
      }}>Is Active {getIconAscDesc(id)}</div>,
      accessor:'isActive',
      width:'10%'
    },{
      Header:'Options',
      className:'options',
      show:["1","2"].includes(userRole),
      Cell:({row:{original:{registerSN,moduleSN,module}},row:{original}})=>{
        return showDeleted ? 
          <div className='customButtonGroup'>
            {(["1"].includes(userRole))&&
              <div
                onClick={()=>{hardDeleteLicense(registerSN,moduleSN,module)}}
                style={{backgroundColor:'#084298',width:'30px',height:'30px',color:'white'}}
                title='Hard Delete'
                className='customButton'>
                  <FaSkullCrossbones size={15}/>
              </div>}
              <div
                onClick={()=>{recoverLicense(registerSN,moduleSN,module)}}
                style={{backgroundColor:'#0d6efd',width:'30px',height:'30px',color:'white'}}
                className='customButton'>
                  <TiRefreshOutline size={15}/>
              </div>
          </div>

        : 
        (
          <div className='customButtonGroup'>
            {(["1"].includes(userRole))&&
              <div
                onClick={()=>{hardDeleteLicense(registerSN,moduleSN,module)}}
                style={{backgroundColor:'#084298',width:'30px',height:'30px',color:'white'}}
                title='Hard Delete'
                className='customButton'>
                  <FaSkullCrossbones size={15}/>
              </div>}

            <div
              onClick={()=>{deleteLicense(registerSN,moduleSN,module)}}
              style={{backgroundColor:'#d3002a',width:'30px',height:'30px',color:'white'}}
              title='Soft Delete'
              className='customButton'>
                <HiOutlineTrash size={15}/>
            </div>

            <div
              onClick={()=>{setEditRow(original)}}
              style={{backgroundColor:'#f19241',width:'30px',height:'30px',color:'white'}}
              className='customButton'>
                <FiEdit size={15}/>
            </div>
          </div>
        )
      },
      width:'10%'
    }
    ]
  )

  const StartDateSearch = forwardRef(({ value, onClick,onChange }, ref) => (
    <InputGroup>
      <InputGroupText>Start Date</InputGroupText>
      <Input ref={ref} value={value} onChange={onChange} onClick={onClick} placeholder='...'/>
    </InputGroup>
  ));
  
  const EndDateSearch = forwardRef(({ value, onClick,onChange }, ref) => (
    <InputGroup>
      <InputGroupText>End Date</InputGroupText>
      <Input ref={ref} value={value} onChange={onChange} onClick={onClick} placeholder='...'/>
    </InputGroup>
  ));

  return <>
          <Styles>
            <div className='dataHeader'>
              <div className='searchKit'>
                <div className='column'>
                  <InputGroup>
                    <InputGroupText>Register SN</InputGroupText>
                    <Input value={registerSearch} onChange={e=>setRegisterSearch(e.target.value)} placeholder='...'/>
                  </InputGroup>
                  <InputGroup>
                    <InputGroupText>Module Name</InputGroupText>
                    <Input value={moduleSearch} onChange={e=>setModuleSearch(e.target.value)} placeholder='...'/>
                  </InputGroup>
                  <InputGroup>
                    <InputGroupText>Is Active</InputGroupText>
                    <Input value={isActiveSearch} onChange={e=>setIsActiveSearch(e.target.value)} placeholder='...'/>
                  </InputGroup>
                </div>
                <div className='column'>
                  <DatePicker
                    customInput={<StartDateSearch />}
                    isClearable
                    selected={startDateSearch}
                    dateFormat="yyyy-MM-dd"
                    onChange={e=> {setStartDateSearch(e)}}/>
                  <DatePicker customInput={<EndDateSearch />} isClearable selected={endDateSearch} dateFormat="yyyy-MM-dd" onChange={e=> {setEndDateSearch(e)}}/>
                  <div className='checkBoxContainer'>
                    <div className={'checkBoxCircle '+ (isDeletedSearch?'checkBoxCircleOpen':'checkBoxCircleClose')} onClick={()=>setIsDeletedSearch(()=>{
                      return !isDeletedSearch
                    })}>
                      <span className={'checkBoxText '+ (isDeletedSearch?'checkBoxTextOpen':'checkBoxTextClose')}>{ isDeletedSearch?'Deleted':'Available' }</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='buttonContainer'>
                <div className='pageBox'>
                  <select className='pageSelect' type='number' value={rowsPerPage} onChange={e=>{
                    let {value}=e.target
                    if(value>0){
                      setRows(value)
                    }
                  }}>
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                  </select>
                  <div className='pageText'>Rows Per Page</div>
                </div>
                  {["1","2"].includes(userRole) &&
                    <div style={{backgroundColor:'#d3002a'}}
                      onClick={()=>{setNewModalOpen(true) }}
                      className='customButton'>
                        <TiDocumentAdd size={50}/>
                    </div>}
                  
                  <div style={{backgroundColor:'#42ba96'}} onClick={async ()=>{
                    let el=document.getElementById('scroll')
                    el.scrollTo(0,0)
                    clearSearchAndRefresh()
                  }} className='customButton'><IoMdRefresh size={50}/></div>
                  
                <div style={{backgroundColor:'#f19241'}} onClick={()=>{
                  let el=document.getElementById('scroll')
                  el.scrollTo(0,0)
                  currentPage==1?getLicenses():setCurrentPage(1)
                }} className='customButton'><BiSearchAlt size={50}/></div>
              
              </div>
            </div>
            <hr/>
            <div className='contentContainer'>
              {loading&&<div className='loading'>Loading...</div>}
              <div className='dataContainer' id='scroll'>
                <Table columns={columns} data={licenseData}/>
              </div>
            </div>
            
          <div className='dataFooter'>
              <div className='buttonSetLeft'>
                <button disabled={!(currentPage>1)} onClick={()=>{
                  if(currentPage>1)
                    setCurrentPage(1)
                  }}>{'|<'}</button>
                <button disabled={!(currentPage>1)} onClick={()=>{
                  if(currentPage>1)
                    setCurrentPage(currentPage-1)
                  }}>{'<'}</button>
              </div>
                <div className='currentPage'><input style={{ width:(20+currentPage.toString().length*10)+'px' }} type='number'
                  size={2}
                  value={currentPage}
                  onChange={e=>{
                    let {value}=e.target
                    
                    if(value>0 && value<999){
                      setCurrentPage(value)
                  }
                }}/>/ {totalPages}</div>
              <div className='buttonSetRight'>
                <button disabled={!(currentPage<totalPages)} onClick={()=>{
                    if(currentPage<totalPages)
                      setCurrentPage(currentPage+1)
                }}>{'>'}</button>
                <button disabled={!(currentPage<totalPages)} onClick={()=>{
                    if(currentPage<totalPages)
                      setCurrentPage(totalPages)
                }}>{'>|'}</button>
              </div>
          </div>
          </Styles>
          <NewLicense refresh={()=>getLicenses()} isOpen={newModalOpen} toggle={()=>setNewModalOpen(!newModalOpen)}/>
          <EditLicense userRole={userRole} refresh={()=>getLicenses()} isOpen={editRow!=null} selectedRow={editRow} toggle={()=>setEditRow(null)}/>

        </>
}